import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaLinkedinIn, FaDiscord } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              KISACA <span className="purple"> BENİM </span> HAKKIMDA
            </h1>
            <p className="home-about-body">
              2018 yılından beri web geliştirme ve bilişim alanları üzerine ilgi duymaktayım 🤷‍♂️
              <br />
              <br /> 
              <i>
                <b className="purple"> PHP, Java, Kotlin, JavaScript ve  Visual Basic </b>gibi programlama dillerini biliyorum.
              </i>
              <br />
              <br />
              Şu anda İstatistik ve Bilgisayar Bilimleri 2. sınıf öğrencisiyim ve&nbsp;
              <i>
                <b className="purple">Freelance </b> olarak
                yazılım işleri ile ilgileniyorum.{" "}
                Aynı zamanda<b className="purple"> RadomeWEB Yazılım Hizmetleri</b> üzerinde çalışıyorum.
              </i>
              <br />
              <br />
              Ayrıca şu anda yeni yazılım projelerim için <b className="purple">Grafana, gRPC, Docker</b> ve istatistik projelerim için
              <i>
                <b className="purple">
                  {" "}
                  Matlab ve SPSS
                </b>
              </i>
              &nbsp; gibi yazılımları öğrenmeye çalışıyorum.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>İLETİŞİM ADRESLERİM</h1>
            <p>
              Asağıdaki adresler üzerinden <span className="purple">bana </span>ulaşabilirsiniz
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/BatuhanAcikgoz"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/batuhan-acikgoz"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://discord.gg/CAuEKfcWCp"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
