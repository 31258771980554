import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import RadomeWEB from "../../Assets/Projects/RadomeWEB.png";
import RadomePanel from "../../Assets/Projects/RadomePanel.png";
import RadomeGuilds from "../../Assets/Projects/RadomeGuilds.png";
import RadomeWEBv3 from "../../Assets/Projects/RadomeWEBv3.png";
import RadomeEngine from "../../Assets/Projects/RadomeEngine.png";
import ZoraPVP from "../../Assets/Projects/ZoraPVP.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        <strong className="purple">Projelerimden </strong>Birkaçı
        </h1>
        <p style={{ color: "white" }}>
          Son 1 yılda yaptığım çalışmalar.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RadomeWEB}
              isBlog={false}
              title="RadomeWEB"
              description="Yapımına Nisan 2022'de başlanan Verira ve Awm Bilişim firmaları için yapılmış 2 ayrı altyapı ile gelmektedir. Minecraft Java ve Bedrock sunucuları için PHP 8 destekli geniş özelliklere sahip bir webscript."
              ghLink="https://radome.web.tr/v1"
              demoLink="https://v1.radome.web.tr"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RadomePanel}
              isBlog={false}
              title="RadomePanel"
              description="Verira Bilişim Hizmetleri adına Temmuz 2021 yılında yapılmaya başlanan RadomePanel C# ile yazılmıştır. Minecraft sunucu yönetimi ve CDN üzerinden sunucu dosyası indirme gibi özelliklere sahiptir."
              ghLink="https://verira.com"
              demoLink="https://verira.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ZoraPVP}
              isBlog={false}
              title="ZoraPvP"
              description="Şubat 2021'de kurulan ZoraPvP bir topluluk sunucusu olması ile tanınmaktadır. An itibariyle 5700 kullanıcı ve 1500 aktiflik ile Türkiye'nin en büyük pvp topluluklarından bir tanesi olma özelliğine sahip."
              ghLink="https://zorapvp.xyz/"
              demoLink="https://zorapvp.xyz/"              
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RadomeWEBv3}
              isBlog={false}
              title="RadomeWEB V3"
              description="Yapımına Eylül 2022'de başlanan ZoraPvP için RadomeWEB firması tarafından yapılmış PHP 8.3 tabanlı site yazılımıdır. İçerisinde kullanıcı yönetimi, RadomeEngine-V1 API, mağaza ve ödeme altyapıları desteği gibi bir çok özellik mevcuttur."
              ghLink="https://radome.web.tr/radomeweb"
              demoLink="https://v3.radome.web.tr"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RadomeGuilds}
              isBlog={false}
              title="RadomeGuilds"
              description="ZoraPvP spigot versiyonları için Java 17 kullanılarak yazılmış bir lonca eklentisidr. SQL desteği, ve StrikePractice uyumluğunun yanı sıra party chat, lonca leveli, oyuncu ekleme çıkarma ve GUO gibi bir çok özelliği vardır."
              ghLink="https://radome.web.tr/radome-guilds"
              demoLink="https://radome.web.tr/radome-guilds"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RadomeEngine}
              isBlog={false}
              title="RadomeEngine V1"
              description="Kasım 2023'te yapımına başlanan bu projenin amacı Mojang, Bukkit gibi kütüphanelerin kullanılmadığı daha hızlı ve yeni nesil olan bir kütüphane olan minestom kullanılarak Java ve Kotlin yazılım dilleriyle yazılmış bir sunucu yazılımıdır."
              ghLink="https://radome.web.tr/radome-engine"
              demoLink="https://radome.web.tr/radome-engine"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
