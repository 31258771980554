import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Merhaba, Ben <span className="purple">Batuhan Açıkgöz, </span>
            <span className="purple"> Zonguldak, Türkiye</span>'de yaşıyorum.
            <br />Şu anda İstatistik ve Bilgisayar Bilimleri 2. sınıf öğrencisiyim. 2018 yılından beri bilişim teknolojileri üzerine çalışmalar yapıyorum. Ayrıca 2020 yılından beridir yazılım ile ilgileniyorum
            <br />
            <br />
            Kod yazmaktan bilgisayar oyunlarına aşağıda yer alan bazı ilgi alanlarım.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Bilgisayar Oyunları
            </li>
            <li className="about-activity">
              <ImPointRight /> Blog Yazmak
            </li>
            <li className="about-activity">
              <ImPointRight /> Satranç Oynamak
            </li>
            <li className="about-activity">
              <ImPointRight /> Yazılım Projeleri Tasarlamak
            </li>
            <li className="about-activity">
              <ImPointRight /> Yüzmek
            </li>
            <li className="about-activity">
              <ImPointRight /> Araştırmak
            </li>            
          </ul>
          <br />
          <p style={{ color: "#98E6EC", fontSize: 18}}>
            "Kaybettiğin şeylere bakarak zaman harcama. Devam et, hayat geriye doğru akmaz."{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
